/* eslint-disable camelcase */

import { BodyPart, ScanView } from '../services/organ-view-logic/organ-view-service';

/** ******************************
 * COMMON TYPES
 ****************************** */
export enum ApiStatus {
  INITIAL,
  LOADING,
  SUCCESS,
  FAILED,
}

/** **********************************************************
 *                       GENERIC TYPES
 *********************************************************** */
export interface PaginatedResponse<T> {
  count: number;
  results: T[];
  response: T[];
  total_count: number;
}

export interface PaginatedResponseV2<T>{
  response: T[];
  total_number_of_pages: number;
  total_count: number;
  page_size: number;
  page_number: number;
}

export interface CursorPaginatedResponse<T> {
  next?: string;
  previous?: string;
  results: T[];
}

export function getCursorFromLink(link?: string): string | undefined {
  if (!link) return undefined;

  const paramsStr = link.split('?')[1];
  const urlParams = new URLSearchParams(paramsStr);
  const params = Object.fromEntries(urlParams.entries());
  return params?.cursor;
}

/** ******************************************
 * User and Group
 ******************************************* */

export interface Group {
  name:
    | 'RadiologyCompany'
    | 'RadiologyCompanyManager'
    | 'Customer'
    | 'CustomerCenter'
    | 'Radiologist';
}

export interface User {
  id: string;
  name: string;
  username: string;
  designation?: string;
  reg_no?: string;
  is_active?: boolean;
  is_outsourced?:boolean;
  is_screening?: boolean;
  assign_ai_scans?: boolean;
}

interface RadiologistInfo {
  signature?: string;
  designation?: string;
  reg_no?: string;
  signature_cloud_url?: string;
  is_outsourced?:boolean;
  is_screening?: boolean;
  assign_ai_scans?: boolean;
}

export interface UserDetail {
  id: string;
  username: string;
  name: string;
  email: string;
  first_name: string;
  last_name: string;
  date_joined: string;
  last_login: string;
  groups: Group[];
  centers: CustomerCenter[];
  customer?: Customer;
  userprofile?: {
    prefix?: string;
    phone_no?: string;
  };
  radiologist_info?: RadiologistInfo
}

/** ******************************************
 * Radiologist
 ******************************************* */
export interface RadiologistDetail {
  designation: string;
  reg_no: string;
  profile_picture?: string;
  user: User;
}

export interface RadiologistProfile {
  company: number;
  signature: string | File;
  designation: string;
  reg_no: string;
  payment_type: PaymentType;
  cost1: number;
  cost2: number;
  special_view_cost: number;
}

export type RadiologistProfileUpload = Omit<RadiologistProfile, 'signature'>;

export enum PaymentType {
  HOURLY = 1,
  PER_REPORT = 2,
}

export const PaymentTypeDisplayMap: Record<PaymentType, string> = {
  [PaymentType.HOURLY]: 'Hourly',
  [PaymentType.PER_REPORT]: 'Per Report',
};

export interface RadiologistSummary {
  radiologist: RadiologistProfile;
  schedules: RadiologistWeeklySchedule;
  user: User;
}

export interface RadiologistSession {
  id: number;
  radiologist: number;
  start_time: string | null;
  end_time: string | null;
  weekday: string | null;
  max_scans: number;
  is_backup: boolean;
  max_non_xray_scans: number;
  date: string;
  start_datetime: string;
  end_datetime: string;
  created_by_user_id: number | null;
  created_by: string;
  is_active: boolean;
  type: string;
}

export interface RadiologistSessionList {
  [key: string]: RadiologistSession[]
}

/** ******************************************
 * Customer
 ******************************************* */
export interface Customer {
  user_id: string;
  phone: string;
  address: string;
  report_desc: boolean;
  enable_patient_id: boolean;
  country?: string;
  customer_billing_currency?: string;
}

export interface CustomerCenter {
  id: string;
  name: string;
  report_desc: boolean;
  enable_patient_id: boolean;
  reporting_qualities?: string[]
}

export interface CustomerCenterV2 {
  id: string;
  name: string;
  report_desc: boolean;
  enable_patient_id: boolean;
  radiologist_info: string;
}

export interface CustomerCenterListItem {
  id: string;
  name: string;
  customer_id: number;
  user_id?: number;
}

export interface CustomerCenterForRadiologist {
  id: string;
  report_desc: boolean;
  radiologist_info: string | null;
}

export interface CenterInfo {
  id: string;
  name: string;
  phone?: string;
  customerName: string;
  radiologist_info?: string;
  customer_username?: string;
  center_username?: any;
  center_phone?: string;
  report_desc?: boolean
  enable_patient_id?: boolean
  require_ip_verification?: boolean
  technician_phone?: string
  center_name?: string
  rad_preference_type?: string
  preferred_radiologists?: any[]
  ignored_radiologists?: any[]
}

export interface CenterFullInfo {
  id: number | any;
  name: string;
  customer_username: string;
  center_username: any;
  center_phone: string;
  radiologist_info: string;
  report_desc: boolean
  enable_patient_id: boolean
  require_ip_verification: boolean
  technician_phone: string
  center_name: string
  rad_preference_type: string
  preferred_radiologists: any[]
  ignored_radiologists: any[]
}


export interface RadiologyCompanyContact {
  name?: string;
  contact_info?: string;
}

export interface CustomerSummary {
  id: number;
  username: string;
  center_name?:string;
}

/** ******************************************
 * Blob
 ******************************************* */

export interface Blob {
  id: string;
  original_name?: string;
  object: string;
  container: string;
  url?: string;
  account: string;
  viewer_version?:string;
  patient_info?: string;
  modality?: string;
  taskID?: string;
  radID?: string;
  isEventCaptureEnabled?: boolean;
}

/** ******************************************
 * Study, Series, Patient
 ******************************************* */

export enum PatientGender {
  MALE = 1,
  FEMALE = 2,
  THIRD = 3,
}

export type PatientGenderSting = 'M' | 'F' | '3rd';

export const PatientGenderMap: Record<PatientGender, PatientGenderSting> = {
  [PatientGender.MALE]: 'M',
  [PatientGender.FEMALE]: 'F',
  [PatientGender.THIRD]: '3rd',
};

export type PatientGenderFullSting = 'MALE' | 'FEMALE' | '3rd';

export const PatientGenderFullMap: Record<PatientGender, PatientGenderFullSting> = {
  [PatientGender.MALE]: 'MALE',
  [PatientGender.FEMALE]: 'FEMALE',
  [PatientGender.THIRD]: '3rd',
};

export interface Patient {
  id: string;
  patient_id?: string;
  name: string;
  gender: PatientGender;
  age: number;
  age_month?: number;
  age_days?: number;
}

export interface OrganViewSeriesType {
  organ: BodyPart;
  view?: ScanView[];
}

export interface Series {
  id: string;
  uid?: string;
  description: string | OrganViewSeriesType;
  img_count: number;
  images: Blob[];
  thumbnail?: Blob | null;
  scan_organ?: IScanOrgansList;
  scan_views?: IScanViewList[];
  body_part_examined?: string;
  series_modality?: string;
}

export enum Modality {
  CR = 'CR',
  DX = 'DX',
  CT = 'CT',
  MR = 'MR',
  US = 'US',
  PT = 'PT',
  NM = 'NM',
  MG = 'MG',
  XA = 'XA',
  OT = 'OT',
  PR = 'PR',
  BI = 'BI',
  CD = 'CD',
  DD = 'DD',
  DG = 'DG',
  ES = 'ES',
  LS = 'LS',
  RG = 'RG',
  ST = 'ST',
  TG = 'TG',
  RF = 'RF',
  RTIMAGE = 'RTIMAGE',
  RTDOSE = 'RTDOSE',
  RTSTRUCT = 'RTSTRUCT',
  RTPLAN = 'RTPLAN',
  RTRECORD = 'RTRECORD',
  HC = 'HC',
  IO = 'IO',
  PX = 'PX',
  GM = 'GM',
  SM = 'SM',
  XC = 'XC',
  AU = 'AU',
  EPS = 'EPS',
  HD = 'HD',
  SR = 'SR',
  IVUS = 'IVUS',
  OP = 'OP',
  SMR = 'SMR',
}

let MODALITY_LIST: string[] | undefined;

export function getAllModalityList(): string[] {
  if (MODALITY_LIST !== undefined) return MODALITY_LIST;

  MODALITY_LIST = [];
  Object.keys(Modality).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MODALITY_LIST.push(key);
  });
  return MODALITY_LIST;
}

/** report quality */
export enum ReportingQuality {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  PREMIUM = 'PREMIUM'
}

let REPORTING_QUALITY_LIST: string[] | undefined;

export function getReportQuality(): string[] {
  if (REPORTING_QUALITY_LIST !== undefined) return REPORTING_QUALITY_LIST;

  REPORTING_QUALITY_LIST = [];
  Object.keys(ReportingQuality).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    REPORTING_QUALITY_LIST.push(key);
  });
  return REPORTING_QUALITY_LIST;
}

/** study name type */
export enum StudyNameType {
  MAIN = 'MAIN',
  ADD_ON = 'ADD_ON'
}

let STUDY_NAME_TYPE_LIST: string[] | undefined;

export function getStudyNameType(): string[] {
  if (STUDY_NAME_TYPE_LIST !== undefined) return STUDY_NAME_TYPE_LIST;

  STUDY_NAME_TYPE_LIST = [];
  Object.keys(StudyNameType).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    STUDY_NAME_TYPE_LIST.push(key);
  });
  return STUDY_NAME_TYPE_LIST;
}

export interface Study {
  id: string;
  uid?: string;
  accession_no?: string;
  description: string;
  modality: Modality;
  attatchments: Blob[];
  comments: string;
  study_date: string;
  referring_physician?: string;
  patient: Patient;
  center: CustomerCenter;
  series: Series[];
  data_blob?: Blob;
  receiver_version?: string;
  dicom_study_description?: string;
}

export type StudyForRadiologist = Study & {
  center: CustomerCenterForRadiologist;
};

/** ******************************************
 * Study Report
 ******************************************* */
// info used from processing the report content
export interface TemplateDetailsFromReport {
  templateId: string;
  name: string;
  title: string;
  companyId: string;
  radId: string;
  format: string;
}

export interface StudyReportContent {
  title: string;
  description: string;
  findings: string;
  impressions: string;
  report_content: string;
  task_id?: number;
  report_format?: string;
  template_id?: string | undefined;
  templates_used?:TemplateDetailsFromReport[]|undefined
}
export interface StudyReport {
  id: string;
  report?: Blob;
  report_content?: StudyReportContent;
  report_titles?: string;
  downloaded: boolean;
  task_id?: number;
  report_format?: string;
  version?: number;
  template_id?: string| undefined;
  json_report_url?: string;
  latest_submitted_at?: string | null;
  latest_downloaded_at?: string | null;
}

/** ******************************************
 * Study Report Task
 ******************************************* */

export interface ReportDownloadTemplate {
  id: string;
  name: string;
  templates: string;
}

export enum StudyReportTaskStatus {
  OPEN = 1,
  ASSIGNED = 2,
  DRAFT = 3,
  COMPLETED = 4,
  ISSUE = 5,
  IP_VERIFY = 6,
  OP_VERIFY = 7,
  CANCEL = 8,
}

/**
 * Map of StudyReportTaskStatus to display text, color
 */
export const StudyReportTaskStatsMap: Record<
  StudyReportTaskStatus,
  [string, string | undefined]
> = {
  [StudyReportTaskStatus.OPEN]: ['OPEN', 'gold'],
  [StudyReportTaskStatus.IP_VERIFY]: ['VERIFY', 'purple'],
  [StudyReportTaskStatus.ASSIGNED]: ['ASN', 'orange'],
  [StudyReportTaskStatus.DRAFT]: ['DRAFT', undefined],
  [StudyReportTaskStatus.OP_VERIFY]: ['VERIFY', 'orange'],
  [StudyReportTaskStatus.COMPLETED]: ['DONE', 'green'],
  [StudyReportTaskStatus.ISSUE]: ['CHAT', 'red'],
  [StudyReportTaskStatus.CANCEL]: ['CANCEL', 'red'],
  // [StudyReportTaskStatus.OP_VERIFY]: ['ISSUE', 'red'],
};

export const StudyReportTaskStatsShortMap: Record<
  StudyReportTaskStatus,
  [string, string | undefined]
> = {
  [StudyReportTaskStatus.OPEN]: ['O', 'gold'],
  [StudyReportTaskStatus.IP_VERIFY]: ['V', 'purple'],
  [StudyReportTaskStatus.ASSIGNED]: ['A', 'orange'],
  [StudyReportTaskStatus.DRAFT]: ['D', undefined],
  [StudyReportTaskStatus.OP_VERIFY]: ['OV', undefined],
  [StudyReportTaskStatus.COMPLETED]: ['C', 'green'],
  [StudyReportTaskStatus.ISSUE]: ['I', 'red'],
  [StudyReportTaskStatus.CANCEL]: ['I', 'red'],
};

/**
 * Map of StudyReportTaskStatus to display text, color for Customer
 */
export const StudyReportTaskStatsMapCustomer: Record<
  StudyReportTaskStatus,
  [string, string | undefined]
> = {
  [StudyReportTaskStatus.OPEN]: ['Waiting', undefined],
  [StudyReportTaskStatus.IP_VERIFY]: ['Waiting', undefined],
  [StudyReportTaskStatus.ASSIGNED]: ['Waiting', undefined],
  [StudyReportTaskStatus.DRAFT]: ['Waiting', undefined],
  [StudyReportTaskStatus.OP_VERIFY]: ['Waiting', undefined],
  [StudyReportTaskStatus.COMPLETED]: ['DONE', 'green'],
  [StudyReportTaskStatus.ISSUE]: ['CHAT', 'red'],
  [StudyReportTaskStatus.CANCEL]: ['CANCEL', 'red'],
};

export enum StudyReportTaskPriority {
  NORMAL = 1,
  HIGH = 2,
}

export enum StudyReportTaskBatchType {
  BATCH_START = 1,
  BATCH_MIDDLE = 2,
  BATCH_END = 3,
}

export interface StudyReportTask {
  id: string;
  center: CustomerCenter;
  study: Study;
  status: StudyReportTaskStatus;
  priority: StudyReportTaskPriority;
  reports: StudyReport[];
  report_templates: ReportDownloadTemplate[];
  is_portable: boolean;
  batch_type: StudyReportTaskBatchType;
  batch_size: number;
  batch_leader?: string;
  thread?: number;
  created_at: string;
  completed_at?: string;
  receiver_version?: string;
  status_msg?: string;
  report_format?: string;
  purpose?: string;
  radiologist_tat_end?: string;
  child_outsource_task_mapping?: IOutsourceMapping;
  parent_outsource_task_mapping?: IOutsourceMapping;
  linked_studyreports: StudyReport[];
  upload_status?: string; // can be COMPLETE or inprogress
  is_internal_issue?:boolean;
  linked_studytasks_group_id?: number;
  ai_responses?: AIResponseList
  }

export interface StudyReportTaskShareInput {
  task: string;
  template: string;
  phone_no: string;
  contact_type: number;
  prefix: string;
}

export interface StudyReportTaskShareDetails {
  share_count: number;
  max_share_count: number;
  msg_patient: string;
  msg_referrer: string;
}

export interface StudyReportTaskUpdateVal {
  patientId: string; // Equaivalent of patient.id (server generated id)
  patient_id?: string;
  patient_name: string;
  age: number;
  age_month?: number;
  age_days?: number;
  gender: PatientGender;
  study_date: string;
  referring_physician?: string;
  is_portable?: boolean;
  priority: StudyReportTaskPriority;
  comments: string;
  attatchments: Blob[];
  report_data: {
    [id: string]: {
      title: string;
      desc: string;
    };
  };
  series_data: {
    [id: string]: {
      organ_id: number;
      view_ids: number[];
    };
  };
  study_name?: string;
  description?: string;
  purpose?: string;
  modality?: Modality | null;
  studyreporttask_status?: number | null;
  dicom_study_description?: string;
  billing_study_names?: string;
}

export interface StudyReportTaskDetail extends StudyReportTask {
  radiologist?: User;
  auto_assign_rad?: User;
  billing_study_names?: string;
}

export interface AIResponseResult {
  name: string;
  probability: number;
  is_confirmed: boolean;
}

interface AIInputData {
  age: number;
  gender: string;
  history: string;
  image_url: string;
}

interface AIResponseData {
  results: AIResponseResult[];
  ai_version: string;
  ai_triage_desc: string;
  ai_triage_response: string;
}

interface AIResponseDump {
  id: number;
  ai_version: string;
  ai_input_data: AIInputData;
  ai_description: string;
  ai_response_data: AIResponseData;
  ai_server_status: string;
  ai_triage_response: string;
}

export enum AIRadStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED"
}

export enum AIResponseStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS"
}


export enum AITriageResponse {
  normal = "normal",
  abnormal = "abnormal",
  ignored = "ignored"
}

export interface AIReportType {
  title: string;
  description: string;
  findings: string;
  impressions: string;
  report_content: string;
}

export interface AIResponse {
  id: number;
  series_id: number;
  status: AIResponseStatus;
  ai_triage_response: AITriageResponse;
  ai_description: string;
  ai_version: string;
  ai_report: AIReportType;
  screening_rad_status: AIRadStatus;
  screening_rad_notes: string;
  ai_rad_status: AIRadStatus;
  ai_rad_notes: string;
  is_active: boolean;
  created_at: string;
  modified_at: string;
  response_dump?: AIResponseDump;
  secondary_capture_image_url?: string;
}

export type AIResponseList = AIResponse[];

export type StudyReportTaskForRadiologist = StudyReportTask & { study: StudyForRadiologist } & {radiologist: User} & {ai_responses: AIResponse[]};

export interface StudyReportTaskInput {
  id?: string;
  center: {
    id: string;
    name: string;
  };
  priority: StudyReportTaskPriority;
  is_portable: boolean;
  purpose: string;
  receiver_version?: string;
  draft_task_id?: string;
  study: {
    id?: string;
    uid?: string;
    accession_no?: string;
    description: string;
    dicom_study_description?: string;
    modality: Modality;
    data_blob?: Blob;
    comments?: string;
    attatchments?: Omit<Blob, 'id'>[];
    center: {
      name: string;
    };
    patient: {
      id?: string;
      patient_id?: string;
      name: string;
      gender: PatientGender;
      age: number;
      age_month?: number;
      age_days?: number;
    };
    referring_physician?: string;
    study_date: string;
    series: {
      images: Omit<Blob, 'id'>[];
      thumbnail?: Omit<Blob, 'id'>;
      uid?: string;
      img_count?: number;
      description: string;
      scan_organ?: IScanOrgansList;
      scan_views?: IScanViewList[];
    }[];
  };
}

export interface BulkStudyReportTaskInput {
  center: {
    id: string;
    name: string;
  };
  priority: StudyReportTaskPriority;
  is_portable: boolean;
  purpose:string;
  patient: {
    id?: string;
    patient_id?: string;
    name: string;
    gender: PatientGender;
    age: number;
    age_month?: number;
    age_days?: number;
  };
  study: {
    referring_physician?: string;
    description: string;
    comments?: string;
    attatchments?: Omit<Blob, 'id'>[];
  };
  series: {
    images: Omit<Blob, 'id'>[];
    description: string;
    study_date: string;
  }[];
}

export type BulkStudyReportTaskCreateResponse = { id: string; images: number }[];

/** ******************************************
 * Draft Study Report Task
 ******************************************* */

export interface DraftSeries {
  series_id: string;
  img_count: number;
  description?: string;
  preview_images: Blob[];
  dcm_images: Blob[] | null;  
  scan_organ?: IScanOrgansList;
  scan_views?: IScanViewList[];
}

export interface DraftSeriesV2 {
  id: number;
  task_id: number;
  series_id: string;
  description?: string;
  img_count: number;
  instance_ids: string;
  dcm_images: string[],
  preview_images: string[]
}

export enum DraftStudyReportTaskStatus {
  DRAFT = 1,
  SUBMITTED = 2,
  PROCESSING = 3,
  PROCESS_ERROR = 4,
}

export enum DraftStudyReportTaskStatusV2 {
  DRAFT = 1,
  SUBMITTED = 2, 
  DELETED = 5
}


export interface DraftStudyReportTask {
  id: string;
  center: CustomerCenter;
  patient_id: string;
  name: string;
  gender: PatientGender;
  age: number;
  modality: Modality;
  description: string;
  comments: string;
  data_blob?: Blob;
  accession_no?: string;
  referring_physician: string;
  study_date: string;
  study_time: string;
  series: DraftSeries[];
  created_at: string;
  modified_at: string;
  status: DraftStudyReportTaskStatus;
  receiver_version?: string;
}

export interface DraftStudyReportTaskV2 {
  id: string;
  status: DraftStudyReportTaskStatusV2;
  patient_id: string;
  gender: PatientGender;
  name: string;
  age: number;
  study_id: string;
  accession_no?: string;
  modality: Modality;
  customer_id: string;
  center: CustomerCenterV2;
  description: string;
  comments: string;
  referring_physician: string;
  data_blob?: Blob;
  series: DraftSeriesV2[];
  study_date: string;
  study_time: string;
  receiver_version?: string;
  status_display: string;
  created_at: string;
  modified_at: string;
}

/** ******************************************
 * Radiologist Template
 ******************************************* */

export interface RadiologistTemplateSparse {
  id: string;
  name: string;
  modality: Modality;
  radiologist?: number;
  gender?: ReportTemplateGender;
  report_format?: string;
}
export interface RadiologistTemplate {
  id: string;
  title: string;
  name: string;
  description?: string;
  findings: string;
  impressions: string;
  modality: Modality;
  radiologist?: number;
  radiology_company?: number;
  gender?: ReportTemplateGender;
  report_format?: string;
  report_content?: string;

}

export interface RadiologistTemplateInput {
  title: string;
  name: string;
  description?: string;
  findings: string;
  impressions: string;
  modality: Modality;
  gender?: ReportTemplateGender;
  report_content?: string;
  report_format?: string;
  radiologist_id?: string;
}

/** ******************************************
 * Radiologist Status API for TeleradiologyCompnay
 ******************************************* */

export interface RadiologistStatusInfo {
  // Map of radiologist user id to task count
  rad_task_count: {
    [key: string]: number;
  };
  // Map of radiologist user id to task id
  rad_current_task: {
    [key: string]: number | null;
  };

  // List of online radiologists
  online_rads: User[];

  // Map of csr user id to task id
  csr_current_tasks: {
    [key: string]: number | null;
  };
}

/** ******************************************
 * Billing
 ******************************************* */

export enum Currency {
  RUPEES = 0,
  DOLLARS = 1,
}

export const CurrencyMap: Record<Currency, string> = {
  [Currency.RUPEES]: '₹',
  [Currency.DOLLARS]: '$',
};

export enum BillStatus {
  UNPAID = 0,
  PAID = 1,
  FAILED = 2,
}

export enum BillStatusV2 {
  UNPAID = "unpaid",
  PAID = "Paid",
  FAILED = "Failed",
}

export enum BillRemittance {
  INWARD = 0,
  OUTWARD = 1,
}

export const BillStatusMap: Record<BillStatus, string> = {
  [BillStatus.UNPAID]: 'Unpaid',
  [BillStatus.PAID]: 'Paid',
  [BillStatus.FAILED]: 'Failed',
};

export const BillStatusMapV2: Record<BillStatusV2, string> = {
  [BillStatusV2.UNPAID]: 'Unpaid',
  [BillStatusV2.PAID]: 'Paid',
  [BillStatusV2.FAILED]: 'Failed',
};

export interface BillItem {
  id: string | number;
  description: string;
  amount: number;
}

export interface Bill {
  id: string;
  bill_from_date: string;
  bill_to_date: string;
  description: string;
  remittance: BillRemittance;
  amount: number;
  currency: Currency;
  status: BillStatus;
  attachments: Blob[];
  created_at: string;
  modified_at: string;
  bill_items: BillItem[];

  billed_to: BilledTo;
}

export interface BillPaymentData {
  id: string;
  payment_data: Record<string, any>;
  bill: number;
}

/** ******************************************
 * Notifications
 ******************************************* */

export enum NotificationType {
  GLOBAL = 1,
  NORMAL = 2,
}

export enum NotificationSeverity {
  INFO = 1,
  NORMAL = 2,
  HIGH = 3,
  CRITICAL = 4,
}

export enum NotificationStatus {
  CREATED = 1,
  DELIVERED = 2,
  READ = 3,
}

export enum NotificationActionType {
  REFRESH = 1,
  LINK = 2,
  FUNCTION = 3,
}

interface NotificationActionLink {
  type: NotificationActionType.LINK;
  data: {
    link: string;
  };
}

interface NotificationActionRefresh {
  type: NotificationActionType.REFRESH;
}

interface NotificationActionFunction {
  type: NotificationActionType.FUNCTION;
  data: {
    fn: () => void;
  };
}

type NotificationActionPayload =
  | NotificationActionLink
  | NotificationActionRefresh
  | NotificationActionFunction;

export interface NotificationAction {
  name: string;
  payload: NotificationActionPayload;
}

export interface Notification {
  id: string;
  type: NotificationType;
  isLocal?: boolean;
  severity: NotificationSeverity;
  title?: string;
  content: string;
  actions?: NotificationAction[];
  click_action?: NotificationAction;
  custom_image_url?: string;
  status: NotificationStatus;
  created_at: string;
}

/** ******************************************
 * Customer Support
 ******************************************* */

export enum MessageSenderType {
  HUMAN = 1,
  MACHINE = 2,
}

export interface Message {
  id: number;
  author?: number;
  content: string;
  sender_type: MessageSenderType;
  created_at: string;
}

export interface MessageList {
  messages: Message[];
  users: User[];
}

/** ******************************************
 * Radiologist Schedules
 ******************************************* */

export enum Weekday {
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
  SUN = 7,
}

export interface RadiologistSchedule {
  id: string;
  radiologist: string;
  start_time: string;
  end_time: string;
  weekday: Weekday;
  max_scans: number;
  max_non_xray_scans: number;
  is_backup: boolean;
}

export type RadiologistWeeklySchedule = RadiologistSchedule[];

export type RadiologistScheduleInput = Omit<RadiologistSchedule, 'id'>;

// Radiologist Schedules V2
export interface RadiologistScheduleV2{
  id: number;
  radiologist: number;
  start_time: string;
  end_time: string;
  weekday: Weekday;
  max_scans: number;
  max_non_xray_scans: number;
  is_backup: boolean;
  date: string;
  start_datetime: string;
  end_datetime: string;
  created_by_user_id: string;
  created_by: string;
  is_active: boolean;
  type: string;
}

export interface RadiologistScheduleV2Input {
  max_scans: number;
  is_backup: boolean;
  max_non_xray_scans: number;
  start_datetime?: string;
  end_datetime?: string;
  is_active: boolean;
  weekday?: number;
  start_time?: string;
  end_time?: string;
}

export type RadiologistScheduleV2List = RadiologistScheduleV2[]


/** ******************************************
 * Report Text Expansion
 ******************************************* */

export interface ReportExpansion {
  id: number;
  keyword: string;
  text: string;
}

/** ******************************************
 * Customer Support
 ******************************************* */

export interface UserSessions {
  [key: string]: string[];
}

/** ******************************************
 * TICKET RELATED
 ******************************************* */

export interface TicketComment {
  id: number;
  user: number;
  ticket: number;
  comment: string;
  created_at: string;
}

export enum TicketActivityType {
  CREATE = 0,
  UPDATE = 1,
  ADD_ATTACHMENT = 2,
  ADD_COMMENT = 3,
  DELETE = 4,
}

export const TicketActivityTypeDisplayMap: Record<TicketActivityType, string> = {
  [TicketActivityType.CREATE]: 'Create',
  [TicketActivityType.UPDATE]: 'Update',
  [TicketActivityType.ADD_ATTACHMENT]: 'Add Attachment',
  [TicketActivityType.ADD_COMMENT]: 'Add Comment',
  [TicketActivityType.DELETE]: 'Delete',
};

export interface TicketActivity {
  id: number;
  ticket: number;
  user: number;
  type: TicketActivityType;
  change: string;
  created_at: string;
}

export interface TicketAttachment extends Blob {
  ticket: number;
}

export enum TicketStatus {
  OPEN = 0,
  INPROGRESS = 1,
  BLOCKED = 2,
  DONE = 3,
}

export const TicketStatusDisplayMap: Record<TicketStatus, string> = {
  [TicketStatus.OPEN]: 'Open',
  [TicketStatus.INPROGRESS]: 'In Progress',
  [TicketStatus.BLOCKED]: 'Blocked',
  [TicketStatus.DONE]: 'Done',
};

export enum TicketType {
  REPORT_ISSUE = 0,
  SOFTWARE_ISSUE = 1,
  BILLING_ISSUE = 2,
}

export const TicketTypeDisplayMap: Record<TicketType, string> = {
  [TicketType.REPORT_ISSUE]: 'Report Issue',
  [TicketType.SOFTWARE_ISSUE]: 'Software Issue',
  [TicketType.BILLING_ISSUE]: 'Billing Issue',
};

export enum TicketCustomerType {
  CUSTOMER = 0,
  CUSTOMER_CENTER = 1,
}

export interface IvrsConversation {
  conversation_id: string;
  call_direction: string;
  call_from?: string;
  call_to?: string;
  status: string;
  customer_call_uuid?: string;
  agent_call_uuid: string[];

  // Start time of call (ts of first event) in iso format string
  call_start_ts: string;
  // Updated time of call (ts of last event) in iso format string
  last_update_ts: string;

  // Teleci user id of agent
  telecmi_user_id?: string;
  // Telecmi Team id (only for incoming calls)
  telecmi_team_id?: string;
  // Telecmi request id (only for outgoing calls)
  telecmi_request_id?: string;

  // Telefi User id of agent
  agent_user_id?: string;

  // Phone no of customer (would be either call_from or call_to number)
  customer_phone?: string;
  // User id of customer in Telefi to which calls is made / coming from
  customer_id?: number;
  // Name / Username of customer in Telefi to which calls is made / coming from
  customer_name?: string;
  // Type of customer to which call is made / coming from
  customer_type?: TicketCustomerType;
  // Center id of Customer if applicable
  center_id?: number;

  duration: number;
}

interface IVRSTicketMeta {
  type: 'IVRS';
  payload: IvrsConversation;
}

export type TicketCreateMeta = IVRSTicketMeta;

export interface Ticket {
  id: number;
  create_source: string;
  created_by: string;
  create_meta: TicketCreateMeta;
  type: TicketType;
  status: TicketStatus;
  assignee: number;
  customer?: number;
  customer_name?: string;
  customer_type?: TicketCustomerType;
  title: string;
  desc?: string;
  created_at: string;
  modified_at: string;
}

export interface TicketDetail extends Omit<Ticket, 'assignee'> {
  assignee?: User;
  attachments: string[];
  comments: TicketComment[];
  activity: TicketActivity[];
  users: Record<string, User>;
}

export type TicketUpdateInput = Partial<
  Pick<TicketDetail, 'type' | 'status' | 'assignee' | 'desc'>
>;

/** ******************************************
 * HEALTHCHECK RELATED
 ******************************************* */

export interface HealthCheckData {
  active_call?: IvrsConversation;
}


/** ******************************************
 * MONTHLY BILLS
 ******************************************* */

export interface IMonthlyBills {
  id: number;
  bill_no: string;
  bill_from_date: string;
  bill_to_date: string;
  description: string;
  amount: number;
  status: string;
  billed_to: BilledTo;
  billed_from: BilledFrom;
  attachments: Attachment[];
  pending_bill_amount: number;
  amount_paid_till_now: number;
  last_updated_by_user: any;
  due_date: any;
  is_active: boolean;
  currency?: string;
}

export interface BilledTo {
  id: number;
  name: string;
  username: string;
}

export interface BilledFrom {
  id: number;
  name: string;
  username: string;
}

export interface Attachment {
  id: number;
  object: string;
  container: string;
  account: string;
  url: string;
  original_name: string;
}

// Bill Details for Monthly Bill Section
export interface IBillDetailsForMonthlySection {
  id: number;
  bill_no: string;
  bill_items: IBillItemsV2[];
  success_bill_payments: any[];
  bill_payment_proofs: IBillPaymentProofs[];
  bill_from_date: string;
  bill_to_date: string;
  description: string;
  amount: number;
  status: string;
  billed_to: BilledTo;
  billed_from: BilledFrom;
  attachments: Attachment[];
  pending_bill_amount: number;
  amount_paid_till_now: number;
  last_updated_by_user: any;
  due_date: any;
  is_active: boolean;
  bill_version: string;
  customer_phone: string;
  created_at: string;
  modified_at: string;
  follow_up_date: string;
  remarks: string;
  currency?: string;
  tds_percent: number;
  sgst_percent?: number;
  igst_percent?: number;
  cgst_percent?: number;
  tds_amount: number;
  sgst_amount: number;
  igst_amount: number;
  cgst_amount: number;
  total_pending_amount?: number;
  tds_amount_paid_till_now?: number;
  final_amount?:number;
  bill_amount_with_taxes?: number;
  pending_tds_amount:number;
  
}

export interface IBillItemsV2 {
  id: number
  bill: number
  description: string
  amount: number
  center: number
  created_at: string
  modified_at: string
}

export interface IBillPayment {
  id: number;
  payment_provider: number;
  payment_status: number;
  payment_amount: number;
  paid_via: string;
  payment_purpose: any;
  created_at: string;
  modified_at: string;
  payment_utr_number: string;
  currency?: string;
}

/*
*
* Study Billing issues
*
*/

export interface IStudyBilling {
  id: number;
  studyreporttask: number;
  study: number;
  customer: ICustomerIStudyBilling;
  customer_center: ICustomerCenterIStudyBilling;
  radiologist: Radiologist;
  company: Company;
  patient: IPatientIStudyBilling;
  modality: string;
  views_count: number;
  report_title: string;
  customer_rate1: number;
  customer_rate2: number;
  customer_special_view_rate: number;
  customer_bill_amount: number;
  customer_bill_payment_type: string;
  customer_bill_payment_status: string;
  customer_bill_wallet: any;
  customer_bill_description: string;
  customer_bill_generation_logic_type: string;
  customer_bill_generation_type: string;
  customer_bill_should_verify_manually: boolean;
  customer_bill_remarks: string;
  customer_bill_monthly: any;
  customer_bill_has_dispute: boolean;
  customer_bill_has_dispute_details: string;
  created_at: string;
  modified_at: string;
  study_task_details: IStudyTaskDetails;
  billing_logic_notes?: string;
}
export interface IStudy {
  id: number;
  modality: string;
  accession_no: any;
  uid: any;
  description: any;
  data_blob: any;
  comments: string;
  attatchments_set: any[];
  referring_physician: any;
  study_date: string;
  study_time: string;
  series_set: SeriesSet[];
  receiver_version?: string;
}

export interface SeriesSet {
  uid: any;
  description: string;
  img_count: number;
  images: Image[];
  thumbnail: any;  
  scan_organ?: IScanOrgansList;
  scan_views?: IScanViewList[];
}

export interface Image {
  id: number;
  object: string;
  container: string;
  account: string;
  url: string;
  original_name: string;
  storage_provider: any;
  file_type: any;
}
export interface IStudyTaskDetails {
  id: number;
  status: number;
  study: IStudy;
  priority: number;
  is_portable: boolean;
  reports: IReport[];
  radiologist: number;
  auto_assign_rad: any;
  batch_leader?: number;
  batch_type: number;
  batch_size: number;
  thread: any;
  created_at: string;
  completed_at: string;
  assigned_at: string;
  billing_study_names?: string;
}
export interface IReport {
  id: number;
  report: any;
  report_content: string;
  downloaded: boolean;
}
export interface ICustomerIStudyBilling {
  id: number;
  name: string;
  username: string;
}

export interface ICustomerCenterNightStudyTimeInfo {
  start_time?: string;
  hours?: string;
  minutes?: string;
}

export interface ICustomerCenterIStudyBilling {
  id: number;
  name: string;
  customerName: string;
  phone: string;
  radiologist_info: any;
  night_study_time_info?: ICustomerCenterNightStudyTimeInfo;
}

export interface Radiologist {
  id: number;
  name: string;
  username: string;
}

export interface Company {
  id: number;
  name: string;
  username: string;
}

export interface IPatientIStudyBilling {
  id: number;
  patient_id: string;
  name: string;
  gender: number;
  age: number;
  age_month: number;
  age_days: number;
}


// list scan organs

export interface IScanOrgansList {
  id: number;
  name: string;
  short_name: string;
  is_special_procedure: boolean;
}


// scan view list
export interface IScanViewList {
  id: number;
  name: string;
  short_name: string;
}

// Get oran views
export interface IScanOrganViews {
  organ: IScanOrgansList;
  views: IScanViewList[];
}




// resolved issue
export interface IRessolveMasterBillingIssue {
  masternillingstudyreporttask_id: number;
  customer_bill_amount: number;
  remarks: string;
  should_resolve: boolean;

}

// customers
export interface ICustomers {
  user_id: number;
  username: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  report_desc: boolean;
  enable_patient_id: boolean;
  is_active: boolean;
}

// customer details
export interface ICustomerDetails {
  user_id: number;
  username: string;
  name: string;
  email: string;
  phone: string;
  owner_phone?: string;
  address: string;
  report_desc: boolean;
  enable_patient_id: boolean;
  max_pending_bill?: number;
  customer_status?: string;
  status_message?: string;
  allow_system_to_suspend?:boolean;
  created_at?: string;
  modified_at?: string;
  remarks?: string;
  min_wallet_balance?: number;
  customer_centers: ICustomerCenter[];
  billing_rates?: IBillingRate[];
  is_customer_login_active?: boolean;
  secondary_organ_discount_percentage?: number | null;
}

export interface ICustomerCenterNightInfo {
  start_time?: string | null;
  hours?: number | null;
  minutes?: number | null;
}

export interface ICustomerCenter {
  id: number;
  name: string;
  center_name?:string;
  technician_phone?:string;
  customer_username: string;
  center_username: string;
  center_phone: string;
  radiologist_info: any;
  report_desc: boolean;
  enable_patient_id: boolean;
  require_ip_verification: boolean; 
  center_user_id?: number;
  is_center_login_active?: boolean;  
  rad_preference_type?: string;
  preferred_radiologists?: IPreferredRadiologist[];
  ignored_radiologists?: any[];
  reporting_qualities?: string[];
  night_study_time_info?: ICustomerCenterNightInfo
}

export interface IRatesLinkedStudyNames {
  modality: string;
  study_name: string;
  study_name_type: string;
}

export interface IPreferredRadiologist {
  id: number
  name: string
  username: string
  is_active: boolean
  designation: string
  reg_no: string
  is_outsourced: boolean
}

export interface IBillingRate {
  id: number;
  // customer: number;
  company: number;
  modality: string;
  study_name: string;
  rate1: number;
  rate2: number;
  special_view_rate: number;
  radiologist_rate1?: number;
  radiologist_rate2?: number;
  radiologist_special_view_rate?: number;
  billing_type_logic: string;
  is_active: boolean;
  is_approved: boolean;
  created_at: string;
  modified_at: string;
  customer: ICustomerDetailsForIBillingRate | null;
  study_name_type: string;
  linked_study_names: string;  
  study_name_synonyms: string;
  reporting_quality: string;
  should_use_for_auto_assign: boolean;
}
export interface ICustomerDetailsForIBillingRate {
  id: number
  name: string
  username: string
  is_active: boolean
}


export interface IWalletTransactions {
  id: number;
  user: number;
  balance: number;
  transaction_type: string;
  transaction_amount: number;
  description: string;
  created_at: string;
}

// IBill V2
export interface IBillV2 {
  id: number;
  bill_no: any;
  bill_from_date: string;
  bill_to_date: string;
  description: string;
  amount: number;
  final_amount: number;
  status: string;
  billed_to: BilledTo;
  billed_from: BilledFrom;
  attachments: any[];
  pending_bill_amount: number;
  amount_paid_till_now: number;
  last_updated_by_user: any;
  due_date: string;
  follow_up_date: any;
  is_active: boolean;
  bill_version: string;
  created_at: string;
  modified_at: string;
  customer_phone: string;
  remarks: any;
  currency?: string;
}

// Account status
export interface IAccountStatus {
  status: string;
  status_message: string;
}


export enum EAccountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export interface IUserAlerts {
  id: number
  user_id: number
  user_group_type: string
  alert_type: string
  is_active: boolean
  expiry_date: any
  message: string
  redirect_link: any
  redirect_link_name: any
  created_at: string
  modified_at: string
  remarks: any
}

export interface IError{
  message?: string;
}

export enum ReportTemplateGender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  THIRD = "THIRD",
  ALL = "ALL",
}

// report template gender

let REPORT_TEMP_GENDER_LIST: string[] | undefined;
export function getAllReportTemplateGenderList(): string[] {
  if (REPORT_TEMP_GENDER_LIST !== undefined) return REPORT_TEMP_GENDER_LIST;

  REPORT_TEMP_GENDER_LIST = [];
  Object.keys(ReportTemplateGender).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    REPORT_TEMP_GENDER_LIST.push(key);
  });
  return REPORT_TEMP_GENDER_LIST;
}

// title extraction from report content v2
export function getTitleExtractionFromReport(s: string | undefined ){
  if(s=== undefined){
    return "NA";
  }
  const titleStartIndex = s.indexOf('<strong>Title:</strong><br /><br />') + '<strong>Title:</strong><br /><br />'.length;
const titleEndIndex = s.indexOf('<br /><br /><strong>Description:</strong>');
const title = s.substring(titleStartIndex, titleEndIndex).trim();
return title;

}

// report version
export enum ReportVersions {
  V1 = "V1",
  V2 = "V2",
}

// get gender for report template from patient gender
export function getGenderFromPateintGender(value: number): any | ReportTemplateGender | PatientGender{
  // console.log(value);
  switch (value) {
    case 1:
      return ReportTemplateGender.MALE;
    case 2:
      return ReportTemplateGender.FEMALE;
    case 3:
      return ReportTemplateGender.THIRD;
    default:
      return ReportTemplateGender.ALL;
  }
}
export enum DataEvents {
  GoBack = "go_back",
  DownloadStudies = "download_studies",
  StudyUrl = "study_url",
  Follow_up = "follow_up",
  MenuIcon_Button = "menu_button_icon_open_close",
  PatientInfoMenuButton = "patient_info_menu_button",
  PatientInfoButton = "patient_info_button",
  AllReportsButton = "all_reports_button",
  NewReportsButton = "new_reports_button",
  OpenSelectedReport = "open_selected_report",
  OpenReportingInterface = "open_reporting_interface",
  AutoLoadTemplate = "auto_load_template",
  LoadSelectedTemplate = "load_selected_template",
  SaveTemplate = "save_template",
  EditorInFocus = "editor_in_focus",
  EditorOutFocus = "editor_out_focus",
  PasteOnReportingInterface = "paste_on_reporting_interface",
  PreviewButton = "preview_button",
  PreviewButtonDocx = "Preview Docx",
  SaveReportButton = "save_report_button",
  SubmitReportButton = "submit_report_button",
  DownloadStudiesButton = "download_studies_button",
  DownloadStudiesCompleted = "download_studies_completed",
}


export enum StudyReportPurpose {
  REPORTING = "REPORTING",
  REFERENCE = "REFERENCE",
}


export enum StudyReportStatus {
  OPEN = 1,
  ASSIGNED = 2,
  DRAFT = 3,
  COMPLETED = 4,
  ISSUE = 5,
  IP_VERIFY = 6,
  OP_VERIFY = 7,
  CANCEL = 8,
}

export const StudyReportStatusOptions = [
  { val: "INCOMING", key: StudyReportStatus.OPEN },
  // { val: "ASSIGNED", key: StudyReportStatus.ASSIGNED },
  // { val: "DRAFT", key: StudyReportStatus.DRAFT },
  { val: "COMPLETED", key: StudyReportStatus.COMPLETED },
  { val: "CHAT", key: StudyReportStatus.ISSUE },
  { val: "IP_VERIFY", key: StudyReportStatus.IP_VERIFY },
  { val: "OUTGOING", key: StudyReportStatus.OP_VERIFY },
  // { val: "CANCEL", key: StudyReportStatus.CANCEL },
];
export const shouldShowOption = (status:any) =>
  status === StudyReportStatus.COMPLETED ||
  status === StudyReportStatus.OPEN ||
  status === StudyReportStatus.ISSUE ||
  status === StudyReportStatus.OP_VERIFY;


export interface ICreateCustomer{
  username: string
  password: string
  owner_phone: string
  address: string
  center_name: string
  technician_phone: string;
  secondary_organ_discount_percentage?: number | null;
}

export interface IUpdateCustomerMeta {
  owner_phone: string
  address: string
  center_name: string
  technician_phone: string;
  secondary_organ_discount_percentage?: number | null;
}
export interface ICreateRadiologist {
  username: string
  password: string
  name: string
  designation: string
  reg_no: string
  signature_base64: string;
  secondary_organ_discount_percentage?: number | null;
}

export interface IUpdateRadiologist {
  name: string
  designation: string
  reg_no: string
  signature_base64: string
}

export interface IRadiologost {
  user: IUserRadiologist
  designation: string
  reg_no: string
  signature_base64: string;
  secondary_organ_discount_percentage?: number | null;
}

export interface IUserRadiologist {
  id: number
  name: string
  username: string
}

// JR CSR
export interface IJrCSROrRadCompMgr {
  id: number
  user: IJrCSROrRadCompMgrUser
  company_id: number
  cmi_uid?: string
}

export interface IJrCSROrRadCompMgrUser {
  id: number
  name: string
  username: string
  is_active: boolean
}

// jr csr
export interface ICreateRadCompMgr {
  username: string
  password: string
  name: string
  cmi_uid?: string
}

// update csr
export interface IUpdateRadCompMgr {
  password: string
  name: string
  is_active: boolean
}

// download study list telefi
export interface IDownloadWorkListPostData {
  user_group: string | null
  user_id: string| null
  start_date: string
  end_date: string
}

export interface ITATEndTimePatchData {
  tat_add_mins_from_current_time: number
}


// PACS Billing
export interface IPACSBilling {
  id: number
  billed_to: IPACSBilledTo
  bill_from_date: string
  bill_to_date: string
  description: string
  amount: number
  pending_bill_amount: number
  amount_paid_till_now: number
  status: string
  last_updated_by_user: any
  due_date: any
  follow_up_date: any
  is_active: boolean
  created_at: string
  modified_at: string
  company_bill_items: CompanyBillItem[]
  company_bill_payments: CompanyBillPayment[]
  currency?: string
  tds_percent: number
  sgst_percent?: number
  igst_percent?: number
  cgst_percent?: number
  tds_amount: number
  sgst_amount: number
  igst_amount: number
  cgst_amount: number
  final_amount: number
  invoice_no?: string
  invoice_date?: string
  invoice_url: string
  attachment_url: string;
  bill_classification_or_accounting_code?: string;
  bill_amount_with_taxes: number;
  pending_tds_amount: number;
  
}

export interface IPACSBilledTo {
  id: number
  name: string
  username: string
  is_active: boolean
}

export interface CompanyBillItem {
  id: number
  description: string
  amount: number
  created_at: string
  modified_at: string
}

export interface CompanyBillPayment {
  id: number
  payment_status: string
  payment_amount: number
  payment_description: string
  paid_via: string
  payment_updated_by_user: any
  payment_proofs: PaymentProof[]
  created_at: string
  modified_at: string
}

export interface PaymentProof {
  id: number
  object: string
  container: string
  account: string
  url: string
  original_name: string
}

// download work list table

export interface IDownloadWorkList {
  id: number
  radiology_company_id: number
  user_type: string
  worklist_user: WorklistUser
  start_date: string
  end_date: string
  status: string
  file_blob_url: string
}

export interface WorklistUser {
  id: number
  name: string
  username: string
  is_active: boolean
}

// Payment details

export interface IPaymentDetails {
  payment_transfer_detais: PaymentTransferDetails[]
  message: string
}

export interface PaymentTransferDetails {
  payment_mode: string
  account_no?: string
  ifsc_code?: string
  branch?: string
  bank_name?: string
  micr_code?: string
  message: string
  upi_id?: string
  upi_qr_url?: string
}

// outsource companies list
export interface IOutsourceCompaniesList {
  outsource_company_id: number
  outsource_company_name: string
}

// outsource mapping parent and child

export interface IOutsourceMapping {
  id: number
  parent_task_id: number
  child_task_id: number
  outsource_status: string
  outsource_message: any
  is_active: boolean
}

export interface ISendOutsourceData{ 
  task_id: number
  outsource_company_id: number
}


export interface IRejectOutsourceStudy{ 
  child_task_id: number
  rejection_message: string
}

// View action logs 
export interface IViewActionLogs {
  id: number
  task_id: number
  message: string
  created_at: string
  modified_at: string
}

// Virtual account details
export interface IVirtualAccount {
  user_id: number
  pg_customer_id: string
  pg_vritual_account_customer_identifier: string
  merchant_billing_label: string
  pg_status: string
  pg_description: string
  bank_account_id: string
  bank_ifsc: string
  bank_name: any
  bank_account_number: string
  vpa_id: string
  vpa_username: string
  vpa_handle: string
  vpa_address: string
  vpa_qr_url: string
  status: string
  account_type: string
}

export interface IBillPaymentProofs {
  object: string
  container: string
  url: any
  original_name: any
  account: string
  id: number
  storage_provider: string
  file_type: string
  created_at: string
  modified_at: string
}

export interface IRadPrefType {
  rad_preference_type: number
}

export interface IAddPrefRadiologist {
  radiologist_id: number
}

enum Preference {
  ANY = 1,
  PREFER = 2,
  STRICT = 3,
}


// link studeis
export interface ILinkStudiesTaskIDS{
  task_ids: string[];
}

// report title study name mapping
export interface ReportTitleStudyName {
  id: number;
  report_title: string;
  study_names: string;
  company_id: number;
  modality: string;
  is_active: boolean;
  created_at: string;
  modified_at: string;
  remarks: string;
}

export type ReportTitleStudyNameMappingFilters = Partial<ReportTitleStudyName>